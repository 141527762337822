import { ValidationFunctionType } from "@/models/CustomTypes";

export const required: ValidationFunctionType = (v: unknown) =>
  v === 0 ? true : !!v || "Pole jest wymagane.";

export function minLength(minLength: number) {
  return function(v: string) {
    const input: string = v ? v.toString() : "";
    return input.length >= minLength ? true : `Minimalna liczba znaków: ${minLength}.`;
  };
}
