



























































import Vue from 'vue';
import {Ref, VModel} from 'vue-property-decorator';
import AgreementAutocomplete from '@/components/form-controls/AgreementAutocomplete.vue';
import FinancialInstrumentAutocomplete from '@/components/form-controls/FinancialInstrumentAutocomplete.vue';
import Component from 'vue-class-component';
import ClientAutocomplete from '@/components/form-controls/ClientAutocomplete.vue';
import {correctNip, minLength, required} from '@/context/recommendations/utils/validations';
import {ValidationFunctionType, VForm} from '@/models/CustomTypes';
import defaultClientObject from "@/context/recommendations/utils/default-client-object";
import {ClientFormModel} from "@/context/clients/models/ClientFormModel";
import PurchaseStrategyAutocomplete from "@/components/form-controls/PurchaseStrategyAutocomplete.vue";

@Component({
  components: {
    PurchaseStrategyAutocomplete,
    AgreementAutocomplete,
    FinancialInstrumentAutocomplete,
    ClientAutocomplete
  }
})
export default class CreateClientForm extends Vue {
  @VModel({
    default: defaultClientObject(),
    type: Object
  }) model: ClientFormModel;
  nipRules: any[] = [required, correctNip];
  companyRules: any[] = [required, minLength(5)];
  strategyRules: any[] = [required];

  @Ref("form")
  readonly form!: VForm;

  submit(): boolean {
    return this.form.validate();
  }

  resetValidation() {
    this.form.resetValidation();
  }

}
