






















import Component from "vue-class-component";
import StepperPageLayout from "@/components/layouts/StepperPageLayout.vue";
import DetailForm from "@/context/agreements/components/create-agreement/forms/DetailForm.vue";
import {
  AgreementFormModel,
  FinancialInstrumentFormModel
} from "@/context/agreements/model/AgreementFormModel";
import VolumesForm from "@/context/agreements/components/create-agreement/forms/VolumesForm.vue";
import FinancialInstrumentForm from "@/context/agreements/components/create-agreement/forms/FinancialInstrumentForm.vue";
import TemplateFinancialInstrumentForm from "@/context/agreements/components/create-agreement/forms/TemplateFinancialInstrumentForm.vue";
import { Ref, VModel, Watch } from "vue-property-decorator";
import Vue from "vue";
import { value, VForm } from "@/models/CustomTypes";

@Component({
  components: {
    StepperPageLayout,
    DetailForm,
    VolumesForm,
    FinancialInstrumentForm,
    TemplateFinancialInstrumentForm
  }
})
export default class FinancialInstrumentPreview extends Vue {
  @VModel() localModel: FinancialInstrumentFormModel[];
  panels = [];

  validate() {
    console.log("Validating in financialInstrumentPreview");
    let formNumber: any = (this.$refs.forms as Vue[]).length;
    let result = true;
    for (let i = 0; i < formNumber; i++) {
      console.log(i);
      console.log(this.$refs.forms[i].validate());
      result = result && this.$refs.forms[i].validate();
    }
    return result;
  }
  initializePanels() {
    for (let i = 0; i < this.localModel.length; i++) {
      this.panels.push(i);
    }
  }
}
