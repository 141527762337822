export enum WalletType {
  Y = "Y",
  Q = "Q",
  M = "M"
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WalletType {
  export function fromString(str: string) {
    return WalletType[str];
  }
}
