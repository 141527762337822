



















import Vue from "vue";
import Component from "vue-class-component";
import { FormComponent, VForm } from '@/models/CustomTypes';
import {CustomerApi} from "@/api/CustomerApi";
import {ToastOptions} from "vue-toasted";
import {CreateClientCmd} from "@/context/clients/models/CreateClientCmd";
import {ClientFormModel} from "@/context/clients/models/ClientFormModel";
import defaultClientObject from "@/context/recommendations/utils/default-client-object";
import CreateClientForm from "@/context/clients/components/forms/CreateClientForm.vue";

@Component({
  components: {CreateClientForm}
})
export default class ClientCreatePage extends Vue {

  model: ClientFormModel = defaultClientObject();

  submit() {
    let validationResult = true;
    const ref: FormComponent = this.$refs['form'] as FormComponent;
    const result = ref.submit();
    validationResult = validationResult && result;
    if (validationResult) {
      this.createClient();
    } else {
      this.showErrorDialog();
    }
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }


  async createClient() {
    try {
      const createCmd: CreateClientCmd =
        new CreateClientCmd(
          this.model.company,
          this.model.nip,
          this.model.purchaseStrategy?.id,
          this.model.realisation,
          this.model.purchaseStrategy?.description
        )
      const response = await CustomerApi.create(createCmd);
      this.model = defaultClientObject();
      let options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.$toasted.success("Klient został zapisany.", options);
      this.form.resetValidation();
      // this.$router.push("/clients/list");
    } catch (e) {
      let options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.$toasted.error("Wystąpił błąd przy zapisie klienta.", options);
    }
  }

  showErrorDialog() {
    let options: ToastOptions = {
      type: "error",
      icon: "mdi-alert-circle",
      duration: 5 * 1000,
      position: "top-right"
    };
    this.$toasted.error(
      "Formularz zawiera błędy uniemożliwiające utworzenie klienta. Sprawdź formularz i spróbuj ponownie.",
      options
    );
  }
}
