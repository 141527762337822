import { axiosInstance as axios } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { CreateRecommendationCmd } from "@/context/recommendations/models/recommendations/CreateRecommendationCmd";
import { CreatePriceApprovalCmd } from "@/context/price-approval/models/CreatePriceApprovalCmd";

export class PriceApprovalApi {
  static baseUrl: string = "/api/price_approvals/";

  public static async update(data: CreatePriceApprovalCmd): Promise<AxiosResponse> {
    return await axios.post(PriceApprovalApi.baseUrl, data);
  }
}
