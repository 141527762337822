


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {RECOMMENDATION_RECIPIENT_API} from '@/api/RecommendationRecipientApi';
import {RecommendationRecipientListModel} from '@/context/recommendations/models/recipients/RecommendationRecipientListModel';
import {RecommendationRecipientFormModel} from '@/context/recommendations/models/recipients/RecommendationRecipientFormModel';
import ClientAutocomplete from '@/components/form-controls/ClientAutocomplete.vue';
import {ValidationFunctionType, VForm} from '@/models/CustomTypes';
import {emailRule, required} from '@/context/recommendations/utils/validations';
import RecipientTypeAutocomplete from '@/components/form-controls/RecipientTypeAutocomplete.vue';
import RecipientListDownloader from "@/context/recommendations/components/recipient/RecipientListDownloader.vue";
import {RecipientType} from "@/models/recommendation-recipient/RecipientType";
import CreateRecipientForm from "@/context/recommendations/components/recipient/form/RecipientForm.vue";
import RecipientForm from "@/context/recommendations/components/recipient/form/RecipientForm.vue";

@Component({
  components: {
    RecipientForm,
    CreateRecipientForm, RecipientListDownloader, RecipientTypeAutocomplete, ClientAutocomplete}
})
export default class RecipientsListPage extends Vue {

  headers: Array<Record<string, unknown>> = [
    {
      text: '#',
      value: 'id',
      align: 'center'
    },
    {
      text: 'Imię',
      value: 'first_name',
      align: 'center'
    },
    {
      text: 'Nazwisko',
      value: 'last_name',
      align: 'center'
    },
    {
      text: 'Email',
      value: 'email',
      align: 'center'
    },
    {
      text: "Klient",
      value: "client",
      align: "center"
    },
    {
      text: 'Operacje',
      value: 'actions',
      align: 'center',
      sortable: false
    }
  ];
  dialog: boolean = false;
  dialogDelete: boolean = false;
  items: RecommendationRecipientListModel[] = [];
  editedIndex: number = -1;
  editedItem: RecommendationRecipientFormModel = new RecommendationRecipientFormModel(undefined, undefined, undefined, undefined, undefined, 'RECOMMENDATION_CONFIRMATION');
  defaultItem: RecommendationRecipientFormModel = new RecommendationRecipientFormModel(undefined, undefined, undefined, undefined, undefined, 'RECOMMENDATION_CONFIRMATION');
  search: string = '';
  itemToDelete: RecommendationRecipientListModel = new RecommendationRecipientListModel();
  recipientType: string = RecipientType.RECOMMENDATION_CONFIRMATION;

  get formTitle() {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  @Watch('dialog', {})
  onDialogChanged(price: boolean) {
    price || this.close();
  }

  @Watch('dialogDelete', {})
  dialogDeleteWatcher(price: boolean) {
    price || this.closeDelete();
  }

  mounted() {
    this.loadItems();
  }

  async loadItems() {
    this.dialog = false
    const response = await this.$http.get(RECOMMENDATION_RECIPIENT_API.baserUrl + '/?search=' + this.search + '&type=RECOMMENDATION_CONFIRMATION');
    this.items = response.data;
  }

  async editItem(item: RecommendationRecipientListModel) {
    this.editedIndex = this.items.indexOf(item);
    const responseRecommendationRecipient = await this.$http.get(RECOMMENDATION_RECIPIENT_API.baserUrl + '/efm/' + item.id);
    const recommendationRecipient = responseRecommendationRecipient.data;
    this.editedItem = Object.assign({}, recommendationRecipient);
    this.dialog = true;
  }

  async deleteItem(item: RecommendationRecipientListModel) {
    this.editedIndex = this.items.indexOf(item);
    this.itemToDelete = Object.assign(new RecommendationRecipientListModel(), item);
    this.dialogDelete = true;
  }

  async deleteItemConfirm() {
    const response = await this.$http.delete(RECOMMENDATION_RECIPIENT_API.baserUrl + '/delete/' + this.itemToDelete.id);
    await this.loadItems();
    this.closeDelete();
  }

  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign(new RecommendationRecipientFormModel(), this.defaultItem);
      this.editedIndex = -1;
    });
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign(new RecommendationRecipientFormModel(), this.defaultItem);
      this.editedIndex = -1;
    });
  }

}
