export class RecommendationRecipientCmd {
  id: number | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  email: string | undefined;
  client: number | undefined;
  type: string | undefined;

  constructor(
    id?: number | undefined,
    first_name?: string | undefined,
    last_name?: string | undefined,
    email?: string | undefined,
    client?: number | undefined,
    type?: string | undefined
  ) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.client = client;
    this.type = type;
  }
}
