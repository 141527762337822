import Vue from "vue";
import Component from "vue-class-component";
import { VForm } from "@/models/CustomTypes";
import { ToastOptions } from "vue-toasted";

@Component({})
export default class AbstractFormPage extends Vue {
  protected defaultErrorMessage = "Wysłanie formularza spowodowało błąd.";

  protected get form(): VForm {
    return this.$refs.form as VForm;
  }

  protected submit(): void {
    const validationResult = this.form.submit();
    if (validationResult) {
      this.$emit("submit:success");
    } else {
      this.$emit("submit:error");
      const options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.showToast(options, this.defaultErrorMessage);
    }
  }

  // todo: zastanowić się nad api
  protected showToast(options: ToastOptions, message: string): void {
    this.$toasted.show(message, options);
  }
}
