import { ClientAutocompleteType, Nullable } from "@/models/CustomTypes";

export class RecommendationRecipientListModel {
  id: number | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  email: string | undefined;
  client: Nullable<ClientAutocompleteType>;

  constructor(
    id?: number | undefined,
    first_name?: string | undefined,
    last_name?: string | undefined,
    email?: string | undefined,
    client?: Nullable<ClientAutocompleteType>
  ) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.client = client;
  }
}
