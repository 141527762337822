






















































import AbstractForm from "@/components/forms/AbstractForm";
import {VModel} from "vue-property-decorator";
import {
  ContactPerson,
  EligiblePersonsForPurchaseFromModel
} from "@/context/agreements/model/AgreementFormModel";
import {required} from "@/context/shared-kernel/validations";
import Component from "vue-class-component";
import NumberInput from "@/components/form-controls/NumberInput.vue";
import FormLayout from "@/components/layouts/FormLayout.vue";
import TextInput from "@/components/form-controls/TextInput.vue";
import EmployeeInput from "@/components/form-controls/EmployeeInput.vue";

@Component({
  components: {EmployInput: EmployeeInput, NumberInput, FormLayout, TextInput }
})
export default class EligiblePersonsForPurchaseForm extends AbstractForm{
  valid = true;
  rules = {
    phoneNumber: [v => !v || /^\+\d{6,}$/.test(v) || 'Numer telefonu musi być poprawny', required],
    email: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email musi być poprawny', required],
    required: [required]
  };
  @VModel({
    default: null,
    type: EligiblePersonsForPurchaseFromModel
  })
  model: EligiblePersonsForPurchaseFromModel;

  add(isPredefined): void {
    if(isPredefined){
      this.model.persons.push(null)
    } else {
      this.model.persons.push(new ContactPerson(
        '',
        '',
        '',
        ''
      ));
    }
  }

  deleteRow(rowId): void {
    this.model.persons.splice(rowId, 1)
  }

}
