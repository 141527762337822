























// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";

@Component
export default class YearInput extends Vue {
  @Prop({ default: "Rok" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: "" }) hint: string;
  @Prop({ default: () => [] }) rules: [];
  @Prop({ default: 3 }) yearNumber: number;
  @Prop({ default: "" }) icon: number;
  @VModel({ type: [Number, Object, String] }) localValue: value;
  items: { id: string; name: string }[] = [];
  created(): void {
    for (let i = 0; i <= this.yearNumber; i++) {
      this.items.push({
        id: (new Date().getFullYear() + i).toString(),
        name: (new Date().getFullYear() + i).toString()
      });
    }
    this.items.unshift({
      id: (new Date().getFullYear() - 1).toString(),
      name: (new Date().getFullYear() - 1).toString()
    });
  }
}
