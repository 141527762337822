












































































































import Component from "vue-class-component";
import AbstractForm from "@/components/forms/AbstractForm";
import FormLayout from "@/components/layouts/FormLayout.vue";
import { Prop, VModel } from "vue-property-decorator";
import { FinancialInstrumentFormModel } from "@/context/agreements/model/AgreementFormModel";
import YearInput from "@/components/form-controls/YearInput.vue";
import DateInput from "@/components/form-controls/DateInput.vue";
import NumberInput from "@/components/form-controls/NumberInput.vue";
import TextInput from "@/components/form-controls/TextInput.vue";
import TimePeriodTypeAutocomplete from "@/components/form-controls/TimePeriodTypeAutocomplete.vue";
import WalletTypeAutocomplete from "@/components/form-controls/WalletTypeAutocomplete.vue";
import { required } from "@/context/shared-kernel/validations";

@Component({
  components: {
    NumberInput,
    FormLayout,
    YearInput,
    DateInput,
    TextInput,
    WalletTypeAutocomplete,
    TimePeriodTypeAutocomplete
  }
})
export default class TemplateCertificateForm extends AbstractForm {
  // Is this necessary?
  // @Prop({ default: () => 1, type: Number }) step: number;
  @VModel({ default: () => new FinancialInstrumentFormModel(), type: Object }) localModel;
  c = console.log;
  reset(): void {
    this.localModel = new FinancialInstrumentFormModel();
  }
  rules = {
    year: [required],
    orderTime: [required],
    minLotNumber: [required],
    maxLotNumber: [required],
    minVolumePerLot: [required],
    maxVolumePerLot: [required],
    yesterdayDifference: [required],
    priceOnDay: [required],
    dailyLotNumber: [required],
    fee: [required],
    meanCalculationBeginDate: [required],
    walletType: [required]
  };
}
