import { Nullable } from "@/models/CustomTypes";

export default class IdNameSelect {
  id?: Nullable<number>;
  name?: Nullable<string>;
  constructor(id?: Nullable<number>, name?: Nullable<string>) {
    this.id = id;
    this.name = name;
  }
}
