


















import Vue from "vue";
import Component from "vue-class-component";
import CreateRecommendationForm from "@/context/recommendations/components/recommendations/forms/CreateRecommendationForm.vue";
import { defaultPriceApprovalObject } from "@/context/recommendations/utils/default-recommendation-object";
import { VForm } from "@/models/CustomTypes";
import { ToastOptions } from "vue-toasted";
import PriceApprovalForm from "@/context/price-approval/components/form/PriceApprovalForm.vue";
import { PriceApprovalFormModel } from "@/context/price-approval/models/PriceApprovalFormModel";
import { CreatePriceApprovalCmd } from "@/context/price-approval/models/CreatePriceApprovalCmd";
import { PriceApprovalApi } from "@/api/PriceApprovalApi";

@Component({
  components: {
    PriceApprovalForm,
    CreateRecommendationForm
  }
})
export default class PriceApprovalPage extends Vue {
  model: PriceApprovalFormModel = defaultPriceApprovalObject();

  async doApprovals() {
    try {
      const approvalCmd: CreatePriceApprovalCmd = CreatePriceApprovalCmd.fromModel(this.model);
      const response = await PriceApprovalApi.update(approvalCmd);
      let options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.$toasted.success("Ceny zostały potwierdzone.", options);
      this.$router.go(0);
    } catch (e) {
      let options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      this.$toasted.error("Wystąpił błąd przy próbie potwierdzenia cen.", options);
    }
  }

  showErrorDialog() {
    let options: ToastOptions = {
      type: "error",
      icon: "mdi-alert-circle",
      duration: 5 * 1000,
      position: "top-right"
    };
    this.$toasted.error(
      "Formularz zawiera błędy uniemożliwiające utworzenie rekomendacji. Sprawdź formularz i spróbuj ponownie.",
      options
    );
  }

  async submit() {
    const form = this.$refs.form as VForm;
    const result = form.submit();
    if (result) {
      await this.doApprovals();
    } else {
      this.showErrorDialog();
    }
  }
}
