






















































import Vue from "vue";
import { Prop, Ref, VModel } from "vue-property-decorator";
import AgreementAutocomplete from "@/components/form-controls/AgreementAutocomplete.vue";
import FinancialInstrumentAutocomplete from "@/components/form-controls/FinancialInstrumentAutocomplete.vue";
import Component from "vue-class-component";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import { emailRule, required } from "@/context/recommendations/utils/validations";
import { ValidationFunctionType, VForm } from "@/models/CustomTypes";
import { ToastOptions } from "vue-toasted";
import { RecommendationRecipientFormModel } from "@/context/recommendations/models/recipients/RecommendationRecipientFormModel";
import RecipientTypeAutocomplete from "@/components/form-controls/RecipientTypeAutocomplete.vue";
import { RecipientType } from "@/models/recommendation-recipient/RecipientType";
import { RecommendationRecipientCmd } from "@/context/recommendations/models/recipients/RecommendationRecipientCmd";
import { RECOMMENDATION_RECIPIENT_API } from "@/api/RecommendationRecipientApi";
import _ from "lodash";

@Component({
  components: {
    RecipientTypeAutocomplete,
    AgreementAutocomplete,
    FinancialInstrumentAutocomplete,
    ClientAutocomplete
  }
})
export default class RecipientForm extends Vue {
  @Prop({ default: RecipientType.RECOMMENDATION })
  recipientType: string;

  @Prop({ default: 'Formularz odbiorcy' })
  formTitle: string;

  @VModel({
    default: new RecommendationRecipientFormModel(),
    type: RecommendationRecipientFormModel
  })
  model: RecommendationRecipientFormModel;

  @Ref('form')
  readonly form!: VForm;

  firstNameRules: ValidationFunctionType[] = [required];
  lastNameRules: ValidationFunctionType[] = [required];
  emailRules: ValidationFunctionType[] = [required, emailRule];
  clientRules: ValidationFunctionType[] = [];
  typeRules: ValidationFunctionType[] = [required];
  submitting: boolean = false;

  async submit() {
    const validation: boolean = this.form.validate();
    if (validation) {
      this.submitting = true;
      try {
        const cmd: RecommendationRecipientCmd = this.buildCmd(this.model);
        await this.save(cmd);
        let options: ToastOptions = {
          type: 'error',
          icon: 'mdi-alert-circle',
          duration: 5 * 1000,
          position: 'top-right'
        };
        this.$toasted.success('Odbiorca został zapisany.', options);
        this.$emit('reloadItems');
      } catch (error) {
        let options: ToastOptions = {
          type: 'error',
          icon: 'mdi-alert-circle',
          duration: 5 * 1000,
          position: 'top-right'
        };
        const errorMessage = error.response.data;
        this.$toasted.error(errorMessage, options);
      }
      this.submitting = false;
    }
  }

  buildCmd(item: RecommendationRecipientFormModel) {
    const clonedFormModel: RecommendationRecipientFormModel = _.cloneDeep(item);
    return new RecommendationRecipientCmd(
      clonedFormModel.id,
      clonedFormModel.first_name,
      clonedFormModel.last_name,
      clonedFormModel.email,
      clonedFormModel.client?.id,
      clonedFormModel.type
    );
  }

  resetForm(): void {
    this.form.reset();
  }

  async save(cmd: RecommendationRecipientCmd) {
    if (cmd.id != null) {
      console.log(cmd.client);
      await this.$http.put(RECOMMENDATION_RECIPIENT_API.baserUrl + '/update/' + cmd.id, cmd);
    } else {
      await this.$http.post(RECOMMENDATION_RECIPIENT_API.baserUrl + '/create', cmd);
    }
  }

  mounted() {
    this.form.resetValidation();
    this.model.type = this.recipientType;
  }
}
