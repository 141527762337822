
















import Component from "vue-class-component";
import StepperPageLayout from "@/components/layouts/StepperPageLayout.vue";
import AbstractFormPage from "@/components/forms/AbstractFormPage";
import DetailForm from "@/context/agreements/components/edit-agreement/forms/DetailForm.vue";
import { AgreementFormModel } from "@/context/agreements/model/AgreementFormModel";
import VolumesForm from "@/context/agreements/components/edit-agreement/forms/VolumesForm.vue";
import FinancialInstrumentsForm from "@/context/agreements/components/edit-agreement/forms/FinancialInstrumentForm.vue";
import StepperContent from "@/context/agreements/components/edit-agreement/StepperContent.vue";
import StepperHeader from "@/context/agreements/components/edit-agreement/StepperHeader.vue";
import { AgreementApi } from "@/api/AgreementApi";
import { ToastOptions } from "vue-toasted";
import { AxiosResponse } from "axios";
@Component({
  components: {
    StepperContent,
    StepperHeader,
    FinancialInstrumentsForm,
    VolumesForm,
    DetailForm,
    StepperPageLayout
  }
})
export default class EditAgreementContainer extends AbstractFormPage {
  step = 1;
  formModel: AgreementFormModel = new AgreementFormModel();
  async mounted() {
    const agreement: AxiosResponse<AgreementFormModel> = await AgreementApi.find(
      parseInt(this.$route.params.id)
    );
    this.formModel = new AgreementFormModel(agreement.data);
    console.log(this.formModel);
  }
  async onSubmit() {
    try {
      await AgreementApi.update(this.formModel.toCommand(), parseInt(this.$route.params.id));
      const options: ToastOptions = {
        type: "success",
        duration: 5 * 1000,
        position: "top-right"
      };
      const message = "Umowa została dodana.";
      this.$toasted.show(message, options);
    } catch (e) {
      const options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      const message = "Wysłanie formularza spowodowało błąd.";
      this.$toasted.show(message, options);
    }
  }
}
