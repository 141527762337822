


















import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import Vue from "vue";
import PurchaseModelInput from "@/components/form-controls/PurchaseModelInput.vue";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";

@Component({})
export default class GroupPurchaseInput extends Vue {
  @VModel({
    type: String
  })
  model: string;

  @Prop({
    default: null,
    type: [Number, Object]
  })
  price: any | undefined;

  @Prop({
    default: "",
    type: String
  })
  queryParam!: string;

  @Prop({ type: Array, default: () => [] })
  rules: [];

  @Prop({
    default: false
  })
  multiple: boolean;

  items: string[] = [];
  loading = false;
  search = "";

  mounted() {
    this.loadItems();
  }

  loadItems() {
    this.loading = true;
    // this.items = [
    //   "Grupa Eurocash",
    //   "Grupa IGLOTEX",
    //   "Grupa Inditex",
    //   "Grupa NEUCA",
    //   "Mali Klienci",
    //   "Mali Klienci Gaz",
    //   "Nie dotyczy",
    //   "Nieruchomości",
    //   "UNIFREEZE"
    // ];
    PurchaseGroupApi.findAll().then(all => {this.items = all.map(item => item.name)});
    this.loading = false;
  }
}
