











import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { FinancialInstrumentApi } from "@/api/FinancialInstrumentApi";
import { value } from "@/models/CustomTypes";

@Component
export default class TextInput extends Vue {
  @Prop({ default: "" }) label: string;
  @Prop({ default: "" }) suffix: string;
  @Prop({ default: true }) dense: boolean;
  @Prop({ default: false }) outlined: boolean;
  @Prop({ default: () => [] }) rules: [];
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @VModel({ type: [Number, Object, String] }) localValue: value;
}
