




















import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import Vue from "vue";
import IdNameSelect from "@/models/IdNameSelect";
import { SellerApi } from "@/api/SellerApi";

@Component({})
export default class SellerInput extends Vue {
  @VModel({
    type: IdNameSelect
  })
  localValue: IdNameSelect;

  @Prop({
    default: "",
    type: String
  })
  queryParam!: string;

  @Prop({ type: Array, default: () => [] })
  rules: [];

  @Prop({
    default: false
  })
  multiple: boolean;

  items: IdNameSelect[] = [];
  loading = false;
  search = "";

  mounted() {
    this.loadItems();
  }

  async loadItems() {
    this.loading = true;
    const response = await SellerApi.select(this.search, this.queryParam);
    this.items = response.data.map(item => new IdNameSelect(item.id, item.name));
    this.loading = false;
  }
}
