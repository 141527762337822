// import axiosConfig from "@/config/axiosConfig";
// import {CreateRecommendationRecipientCmd} from "@/model/recommendation-recipient/CreateRecommendationRecipientCmd";
// import {UpdateRecommendationRecipientCmd} from "@/model/recommendation-recipient/UpdateRecommendationRecipientCmd";

import {axiosInstance} from "@/plugins/axios";
import {AxiosResponse} from "axios";

const baserUrl = "/api/recommendation_recipients"

// export async function create(command: CreateRecommendationRecipientCmd) {
//
//     const response = await axiosConfig.post(baserUrl + "create", command);
//
//     return response;
// }
//
// export async function list(query: string) {
//
//     const response = await axiosConfig.get(baserUrl + "?" + query);
//
//     return response;
// }
//
// export async function remove(id: number) {
//
//     const response = await axiosConfig.delete(baserUrl + "delete/" + id);
//
//     return response;
// }
//
// export async function update(command: UpdateRecommendationRecipientCmd) {
//
//     const response = await axiosConfig.put(baserUrl + "update/"+ command.id, command);
//
//     return response;
// }
//
// export async function get(id: number) {
//
//     const response = await axiosConfig.get(baserUrl + id);
//
//     return response;
// }

export function downloadXls(type: string) {
  const header = {
    'Content-Type': 'multipart/form-data'
  }
  const response = axiosInstance.get(baserUrl + '/xls?type=' + type, {
    headers: header,
    responseType: 'arraybuffer'
  })
    .then((response: AxiosResponse) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'lista odbiorców.xlsx')
      document.body.appendChild(link)
      link.click()
    })
}

export const RECOMMENDATION_RECIPIENT_API = {
  baserUrl,
  downloadXls
}
