




































import Component from "vue-class-component";
import FormLayout from "@/components/layouts/FormLayout.vue";
import Vue from "vue";
import { Prop, Ref, VModel } from "vue-property-decorator";
import MediaTypeInput from "@/components/form-controls/MediaTypeInput.vue";
import SellerInput from "@/components/form-controls/SellerInput.vue";
import GroupPurchaseInput from "@/components/form-controls/GroupPurchaseInput.vue";
import PurchaseModelInput from "@/components/form-controls/PurchaseModelInput.vue";
import MeanCalculationTypeInput from "@/components/form-controls/MeanCalculationTypeInput.vue";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import DateInput from "@/components/form-controls/DateInput.vue";
import { AgreementFormModel } from "@/context/agreements/model/AgreementFormModel";
import { required } from "@/context/shared-kernel/validations";
import { VForm } from "@/models/CustomTypes";
import AbstractForm from "@/components/forms/AbstractForm";

@Component({
  components: {
    DateInput,
    ClientAutocomplete,
    MeanCalculationTypeInput,
    PurchaseModelInput,
    GroupPurchaseInput,
    SellerInput,
    MediaTypeInput,
    FormLayout
  }
})
export default class DetailForm extends AbstractForm {
  rules = {
    mediaType: [required],
    seller: [required],
    purchaseGroup: [required],
    purchaseModel: [required],
    start: [required],
    end: [required],
    offerPreparation: [required],
    meanCalculationType: [required],
    client: [required]
  };
  @VModel({ type: Object }) localModel: AgreementFormModel;
}
