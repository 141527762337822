



















// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";

@Component
export default class PurchaseStrategyAutocomplete extends Vue {
  @Prop({ default: "Strategia zakupów" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: [] }) rules: [];
  @VModel({ type: [Number, Object] }) localValue: value;
  baseUrl = "/api/purchase_strategies/";
  items: [] = [];

  async loadData() {
    const url = this.baseUrl + this.queryParam;
    const { data } = await this.$http.get(url);
    this.items = data;
  }

  mounted() {
    this.loadData();
  }

  async created() {
    if (this.disabled) {
      await this.loadData();
    }
  }
}
