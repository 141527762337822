
































import Component from "vue-class-component";
import StepperPageLayout from "@/components/layouts/StepperPageLayout.vue";
import { Prop } from "vue-property-decorator";
import Vue from 'vue';
@Component({
  components: {
    StepperPageLayout
  }
})
export default class StepperHeader extends Vue {
  @Prop({ default: 1 }) step;
}
