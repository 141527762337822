import { Nullable, PurchaseStrategyType } from "@/models/CustomTypes";

export class CreateClientCmd {
  company: string | undefined;
  nip: string | undefined;
  purchase_strategy: PurchaseStrategyType | undefined | number;
  realisation: boolean | undefined;
  purchase_strategy_description: Nullable<string>;

  constructor(
    company?: string | undefined,
    nip?: string | undefined,
    purchase_strategy?: PurchaseStrategyType | undefined | number,
    realisation?: boolean | false,
    purchase_strategy_description?: Nullable<string>
  ) {
    this.company = company;
    this.nip = nip;
    this.purchase_strategy = purchase_strategy;
    this.realisation = realisation;
    this.purchase_strategy_description = purchase_strategy_description;
  }
}
