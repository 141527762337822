























// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { WalletApi } from "@/api/WalletApi";
import { value } from "@/models/CustomTypes";

@Component
export default class WalletAutocomplete extends Vue {
  @Prop({ default: "Portfel" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) hint: string;
  @Prop({ default: [] }) rules: [];
  @VModel({ type: [Number, Object] }) localValue: value;
  @Prop({ default: "" }) icon: number;

  items: [] = [];

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    const url = `${WalletApi.baseUrl}\\autocomplete?${this.queryParam}`;
    const { data } = await this.$http.get(url);
    this.items = data;
  }

  @Watch("queryParam")
  onQueryParamChange(newVal: value, oldVal: value) {
    if (newVal != oldVal) {
      this.loadData();
    }
  }
}
