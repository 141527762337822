
























































































































import Component from "vue-class-component";
import AbstractForm from "@/components/forms/AbstractForm";
import FormLayout from "@/components/layouts/FormLayout.vue";
import { Prop, Ref, VModel } from "vue-property-decorator";
import { FinancialInstrumentFormModel } from "@/context/agreements/model/AgreementFormModel";
import YearInput from "@/components/form-controls/YearInput.vue";
import DateInput from "@/components/form-controls/DateInput.vue";
import NumberInput from "@/components/form-controls/NumberInput.vue";
import TextInput from "@/components/form-controls/TextInput.vue";
import { MediaType } from "@/models/MediaType";
import TimePeriodTypeAutocomplete from "@/components/form-controls/TimePeriodTypeAutocomplete.vue";
import { required } from "@/context/shared-kernel/validations";
import { VForm } from "@/models/CustomTypes";
import Vue from "vue";
@Component({
  components: {
    NumberInput,
    FormLayout,
    YearInput,
    DateInput,
    TextInput,
    TimePeriodTypeAutocomplete
  }
})
export default class FinancialInstrumentForm extends AbstractForm {
  @VModel({ default: () => new FinancialInstrumentFormModel(), type: Object })
  localModel;
  @Prop({ default: () => 1, type: Number }) step: number;
  reset(): void {
    this.localModel = new FinancialInstrumentFormModel();
  }
  valid = false;
  rules = {
    name: [required],
    year: [required],
    orderTime: [required],
    minLotNumber: [required],
    maxLotNumber: [required],
    minVolumePerLot: [required],
    maxVolumePerLot: [required],
    yesterdayDifference: [required],
    priceOnDay: [required],
    dailyLotNumber: [required],
    fee: [required],
    meanCalculationBeginDate: [required],
    meanCalculationEndDate: [required],
    timePeriodType: [required]
  };
}
