import { Nullable } from "@/models/CustomTypes";

export class PurchaseGroup {
  public id: Nullable<number>;
  public name: Nullable<string>;
  public mediaType: Nullable<string>;
  public year: Nullable<string>;

  constructor(
    id: Nullable<number> = null,
    name: Nullable<string> = null,
    mediaType: Nullable<string> = null,
    year: Nullable<string> = null
  ) {
    this.id = id;
    this.name = name;
    this.year = year;
    this.mediaType = mediaType;
  }

  static fromObject(object: any): PurchaseGroup {
    return new PurchaseGroup(object.id, object.name, object.media_type, object.year);
  }

  static fromObjectsList(objects: PurchaseGroup[]): PurchaseGroup[] {
    return objects.map((val: PurchaseGroup) => PurchaseGroup.fromObject(val));
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      mediaType: this.mediaType,
      year: this.year
    };
  }
}

export class PurchaseGroupMembers extends PurchaseGroup {
  public members: Nullable<PurchaseGroupMember[]>;

  constructor(
    id: Nullable<number> = null,
    name: Nullable<string> = null,
    mediaType: Nullable<string> = null,
    year: Nullable<string> = null,
    members: Nullable<PurchaseGroupMember[]> = null
  ) {
    super(id, name, mediaType, year);
    this.members = members;
  }

  static fromObject(object: any): PurchaseGroupMembers {
    return new PurchaseGroupMembers(
      object.id,
      object.name,
      object.media_type,
      object.year,
      object.members
    );
  }

  static fromObjectsList(objects: PurchaseGroupMembers[]): PurchaseGroupMembers[] {
    return objects.map((val: PurchaseGroupMembers) => PurchaseGroupMembers.fromObject(val));
  }
  toJson() {
    return {
      id: this.id,
      name: this.name,
      mediaType: this.mediaType,
      year: this.year,
      members: this.members
    };
  }
}

export class PurchaseGroupMember {
  public id: Nullable<number>;
  public name: Nullable<string>;

  constructor(id: Nullable<number>, name: Nullable<string>) {
    this.id = id;
    this.name = name;
  }

  toJson() {
    return {
      id: this.id,
      name: this.name
    };
  }
}

export class PurchaseGroupFormModel {
  public name: string;
  public year: string;
  public members: PurchaseGroupMember[];
  currentMember: { id: number; company: string };
}
