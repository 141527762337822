






















// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";
import { required } from "@/context/shared-kernel/validations";
import { ContactPerson } from "@/context/agreements/model/AgreementFormModel";

@Component
export default class EmployeeInput extends Vue {
  @Prop({ default: "Pracownik" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: "Wybierz pracownika" }) hint: string;
  @Prop({ default: () => [required] }) rules: [];
  @VModel({ type: [Number, Object, String] }) localValue: value;
  items: ContactPerson[] = [];
  created(): void {
    this.items.push(
      {
        name: "Artur",
        lastname: "Sarosiek",
        phoneNumber: "+48508456342",
        email: "artur.sarosiek@energysolution.pl"
      },
      {
        name: "Edyta",
        lastname: "Bartoszek",
        phoneNumber: "+48500120698",
        email: "e.bartoszek@energysolution.pl"
      },
      {
        name: "Krzysztof",
        lastname: "Mazurski",
        phoneNumber: "+48501933583",
        email: "k.mazurski@energysolution.pl"
      },
      {
        name: "Janusz",
        lastname: "Kubiak",
        phoneNumber: "+48503596379",
        email: "j.kubiak@energysolution.pl"
      },
      {
        name: "Julita",
        lastname: "Więcław",
        phoneNumber: "+48510679344",
        email: "j.wieclaw@energysolution.pl"
      },
      {
        name: "Aleksandra",
        lastname: "Modrzejewska",
        phoneNumber: "+48516999384",
        email: "a.modrzejewska@energysolution.pl"
      },
      {
        name: "Justyna",
        lastname: "Karczewska",
        phoneNumber: "+48573997829",
        email: "j.karczewska@energysolution.pl"
      }
    );
  }
  getItemText(item): string {
    return `${item.name} ${item.lastname}`;
  }
}
