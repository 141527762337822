import { TimeUnit, TimeUnitUtil } from "@/models/TimeUnit";

export class AgreementFormModel {
  detail: AgreementDetailFormModel;
  volumes: VolumeFormModel[];
  employees: EligiblePersonsForPurchaseFromModel;
  templateInstrument: FinancialInstrumentFormModel;
  templateCertificate: FinancialInstrumentFormModel;
  financialInstruments: FinancialInstrumentFormModel[];
  certificates: FinancialInstrumentFormModel[];

  constructor(agreement?: any) {
    if (!agreement) {
      this.detail = new AgreementDetailFormModel();
      this.volumes = TimeUnitUtil.toVolumeMap();
      this.financialInstruments = [];
      this.certificates = [];
      this.employees = new EligiblePersonsForPurchaseFromModel();
      this.templateInstrument = new FinancialInstrumentFormModel(
        null,
        "",
        "",
        "",
        "",
        1,
        "Y",
        new MarginalConditionFormModel()
      );
      this.templateCertificate = this.templateInstrument = new FinancialInstrumentFormModel(
        null,
        "",
        "",
        "",
        "",
        1,
        "Y",
        new MarginalConditionFormModel()
      );
    } else {
      this.detail = new AgreementDetailFormModel(agreement);
      this.volumes = this.createVolumesMap(agreement.volumes, agreement.volume);
      this.templateInstrument = new FinancialInstrumentFormModel(
        null,
        "",
        "",
        "",
        "",
        1,
        "Y",
        new MarginalConditionFormModel()
      );
      this.templateCertificate = this.templateInstrument = new FinancialInstrumentFormModel(
        null,
        "",
        "",
        "",
        "",
        1,
        "Y",
        new MarginalConditionFormModel()
      );
      this.financialInstruments = this.createFinancialInstruments(agreement);
      this.certificates = this.createCertificates(agreement);
      this.employees = this.createEmployees(agreement);
      // console.log(this.financialInstruments);
      // console.log(this.certificates);
      // throw new Error();
    }
  }

  createFinancialInstruments(agreement: any) {
    const financialInstruments = [];
    const forbiddenNames = ["PMOZE_A", "PMOZE_BIO", "PMEF_F"];
    for (let i = 0; i < agreement.financial_instruments.length; i++) {
      if (forbiddenNames.indexOf(agreement.financial_instruments[i].name) == -1) {
        financialInstruments.push(
          new FinancialInstrumentFormModel(
            agreement.financial_instruments[i].id,
            agreement.financial_instruments[i].name,
            agreement.financial_instruments[i].order_time,
            agreement.financial_instruments[i].price_on_day,
            agreement.financial_instruments[i].yesterday_difference,
            agreement.financial_instruments[i].daily_lot_number,
            agreement.financial_instruments[i].time_unit.id,
            new MarginalConditionFormModel(),
            agreement.financial_instruments[i].year,
            agreement.financial_instruments[i].min_lot_number,
            agreement.financial_instruments[i].max_lot_number,
            agreement.financial_instruments[i].min_volume_per_lot,
            agreement.financial_instruments[i].max_volume_per_lot,
            agreement.financial_instruments[i].mean_calculation_start_date,
            agreement.financial_instruments[i].mean_calculation_end_date,
            agreement.financial_instruments[i].fee,
            agreement.financial_instruments[i].type
          )
        );
      }
    }
    return financialInstruments;
  }

  createCertificates(agreement: any) {
    const financialInstruments = [];
    const allowedNames = ["PMOZE_A", "PMOZE_BIO", "PMEF_F"];
    for (let i = 0; i < agreement.financial_instruments.length; i++) {
      if (allowedNames.indexOf(agreement.financial_instruments[i].name) > 0) {
        financialInstruments.push(
          new FinancialInstrumentFormModel(
            agreement.financial_instruments[i].id,
            agreement.financial_instruments[i].name,
            agreement.financial_instruments[i].order_time,
            agreement.financial_instruments[i].price_on_day,
            agreement.financial_instruments[i].yesterday_difference,
            agreement.financial_instruments[i].daily_lot_number,
            agreement.financial_instruments[i].time_unit.id,
            new MarginalConditionFormModel(),
            agreement.financial_instruments[i].year,
            agreement.financial_instruments[i].min_lot_number,
            agreement.financial_instruments[i].max_lot_number,
            agreement.financial_instruments[i].min_volume_per_lot,
            agreement.financial_instruments[i].max_volume_per_lot,
            agreement.financial_instruments[i].mean_calculation_start_date,
            agreement.financial_instruments[i].mean_calculation_end_date,
            agreement.financial_instruments[i].fee,
            agreement.financial_instruments[i].type
          )
        );
      }
    }
    return financialInstruments;
  }

  createEmployees(agreement: any){
    const employees = [];
    for (const empKey in agreement.employees) {
      employees.push(new ContactPerson(
        agreement.employees[empKey].name,
        agreement.employees[empKey].lastname,
        agreement.employees[empKey].phone_number,
        agreement.employees[empKey].email
      ))
    }
    return new EligiblePersonsForPurchaseFromModel(employees)
  }


  createVolumesMap(volumes: [], volume: number) {
    if (volumes.length > 0) {
      return volumes.map((value: Record<string, any>) => {
        return new VolumeFormModel(value.volume, value.month.name);
      });
    } else {
      const monthVolume = volume / 12;
      return Object.keys(TimeUnit)
        .map((item, index, arr) => [new VolumeFormModel(monthVolume, item)])
        .reduce((result, current) => current.concat(result))
        .reverse();
    }
  }

  public toCommand() {
    return {
      detail: this.detail.toCommand(),
      volumes: this.volumes.map(v => v.toCommand()),
      certificates: this.certificates.map(v => v.toCommand()),
      financialInstruments: this.financialInstruments.map(fi => fi.toCommand()),
      eligiblePersons: this.employees.toCommand()
    };
  }
}

export class AgreementDetailFormModel {
  mediaType: string;
  seller: number;
  purchaseGroup: string;
  purchaseModel: string;
  start: Date;
  end: Date;
  offerPreparation: Date;
  meanCalculationType: string;
  client: number;

  constructor(agreement?: any) {
    if (agreement) {
      this.mediaType = agreement.media_type.id;
      this.seller = agreement.seller;
      this.purchaseGroup = agreement.group_purchase_organization;
      this.purchaseModel = agreement.purchase_model.id;
      this.start = agreement.agreement_start_date;
      this.end = agreement.agreement_end_date;
      this.offerPreparation = agreement.offer_preparation_date;
      this.meanCalculationType = agreement.mean_calculation_type.id;
      this.client = agreement.client;
    }
  }

  public toCommand(): Record<string, unknown> {
    return {
      mediaType: this.mediaType,
      seller: this.seller,
      purchaseGroup: this.purchaseGroup,
      purchaseModel: this.purchaseModel,
      start: this.start,
      end: this.end,
      offerPreparation: this.offerPreparation,
      meanCalculationType: this.meanCalculationType,
      client: this.client
    };
  }
}

export class ContactPerson {
  name: string;
  lastname: string;
  phoneNumber: string;
  email: string;

  constructor(name: string = null,
              lastname: string = null,
              phone_number: string = null,
              email: string = null) {
    this.name = name;
    this.lastname = lastname;
    this.phoneNumber = phone_number;
    this.email = email;
  }
}

export class EligiblePersonsForPurchaseFromModel{
    persons: Array<ContactPerson>;

  constructor(persons: Array<ContactPerson> = []) {
    this.persons = persons;
  }

  public toCommand(): Record<string, unknown> {
    return {
      persons: this.persons
    };
  }
}

export class FinancialInstrumentFormModel {
  id: number;
  name: string;
  year: string;
  orderTime: string;
  priceOnDay: string;
  minLotNumber: number;
  maxLotNumber: number;
  minVolumePerLot: number;
  maxVolumePerLot: number;
  yesterdayDifference: string;
  dailyLotNumber: number;
  meanCalculationBeginDate: string;
  meanCalculationEndDate: string;
  fee: number;
  timePeriodType: string;
  walletType: string;
  marginalCondition: MarginalConditionFormModel;

  constructor(
    id: number = null,
    name: string = null,
    orderTime: string = "n-1",
    priceOnDay: string = "n-1",
    yesterdayDifference: string = "n-1",
    dailyLotNumber: number = 1,
    timePeriodType: string = "Y",
    marginalCondition: MarginalConditionFormModel = new MarginalConditionFormModel(),
    year: string = new Date().getFullYear().toString(),
    minLotNumber: number = 1,
    maxLotNumber: number = 10,
    minVolumePerLot: number = 1,
    maxVolumePerLot: number = 100,
    meanCalculationBeginDate: string = null,
    meanCalculationEndDate: string = null,
    fee: number = 0.0,
    walletType: string = "Y"
  ) {
    this.id = id;
    this.name = name;
    this.year = year;
    this.orderTime = orderTime;
    this.priceOnDay = priceOnDay;
    this.minLotNumber = minLotNumber;
    this.maxLotNumber = maxLotNumber;
    this.minVolumePerLot = minVolumePerLot;
    this.maxVolumePerLot = maxVolumePerLot;
    this.yesterdayDifference = yesterdayDifference;
    this.dailyLotNumber = dailyLotNumber;
    this.meanCalculationBeginDate = meanCalculationBeginDate;
    this.meanCalculationEndDate = meanCalculationEndDate;
    this.fee = fee;
    this.marginalCondition = marginalCondition;
    this.timePeriodType = timePeriodType;
    this.walletType = walletType;
  }

  public toCommand(): Record<string, unknown> {
    return {
      id: this.id,
      name: this.name,
      year: this.year,
      orderTime: this.orderTime,
      priceOnDay: this.priceOnDay,
      minLotNumber: this.minLotNumber,
      maxLotNumber: this.maxLotNumber,
      minVolumePerLot: this.minVolumePerLot,
      maxVolumePerLot: this.maxVolumePerLot,
      yesterdayDifference: this.yesterdayDifference,
      dailyLotNumber: this.dailyLotNumber,
      meanCalculationBeginDate: this.meanCalculationBeginDate,
      meanCalculationEndDate: this.meanCalculationEndDate,
      fee: this.fee,
      marginalCondition: this.marginalCondition,
      timePeriodType: this.timePeriodType,
      walletType: this.walletType
    };
  }
}

export class MarginalConditionFormModel {
  minPrice: number;
  maxPrice: number;
}

export class VolumeFormModel {
  value: number;
  timeUnit: string;

  constructor(value: number, timeUnit: string) {
    this.value = value;
    this.timeUnit = timeUnit;
  }

  public toCommand(): Record<string, unknown> {
    return {
      value: this.value,
      timeUnit: this.timeUnit
    };
  }
}
