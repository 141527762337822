






















import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import AbstractForm from "@/components/forms/AbstractForm";

@Component({
  components: {}
})
export default class FormLayout extends AbstractForm {
  @VModel({ type: [Number, Object, Boolean] }) model;
}
