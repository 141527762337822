














import Vue from 'vue';
import Component from 'vue-class-component';
import {RECOMMENDATION_RECIPIENT_API} from "@/api/RecommendationRecipientApi";
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class RecipientListDownloader extends Vue {

  @Prop({default: ''}) type: string;

  async handle() {
    RECOMMENDATION_RECIPIENT_API.downloadXls(this.type);
  }

}
