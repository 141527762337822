import { ClientFormModel } from "@/context/clients/models/ClientFormModel";

export default function defaultClientObject(): ClientFormModel {
  return {
    company: null,
    nip: null,
    purchaseStrategy: null,
    realisation: false
  };
}
