


















import Component from "vue-class-component";
import FormLayout from "@/components/layouts/FormLayout.vue";
import Vue from "vue";
import TextInput from "@/components/form-controls/TextInput.vue";
import NumberInput from "@/components/form-controls/NumberInput.vue";
import { VModel } from "vue-property-decorator";
import { VolumeFormModel } from "@/context/agreements/model/AgreementFormModel";
import { required } from "@/context/shared-kernel/validations";
import AbstractForm from '@/components/forms/AbstractForm';

@Component({
  components: { NumberInput, FormLayout, TextInput }
})
export default class VolumesForm extends AbstractForm {
  valid = true;
  rules = {
    default: [required]
  };
  @VModel({
    default: {},
    type: [Array]
  })
  model: VolumeFormModel[];
}
