export enum EnergyFinancialInstrumentType {
  BASE_Y = "BASE_Y",
  BASE_Q1 = "BASE_Q-1",
  BASE_Q2 = "BASE_Q-2",
  BASE_Q3 = "BASE_Q-3",
  BASE_Q4 = "BASE_Q-4",
  BASE_M1 = "BASE_M-01",
  BASE_M2 = "BASE_M-02",
  BASE_M3 = "BASE_M-03",
  BASE_M4 = "BASE_M-04",
  BASE_M5 = "BASE_M-05",
  BASE_M6 = "BASE_M-06",
  BASE_M7 = "BASE_M-07",
  BASE_M8 = "BASE_M-08",
  BASE_M9 = "BASE_M-09",
  BASE_M10 = "BASE_M-10",
  BASE_M11 = "BASE_M-11",
  BASE_M12 = "BASE_M-12"
}

// export function toString();
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnergyFinancialInstrumentTypeUtil {
  export function getTimePeriodType(type: EnergyFinancialInstrumentType): string {
    let result: string = "";
    switch (type) {
      case EnergyFinancialInstrumentType.BASE_M1:
        result = "M1";
        break;
      case EnergyFinancialInstrumentType.BASE_M2:
        result = "M2";
        break;
      case EnergyFinancialInstrumentType.BASE_M3:
        result = "M3";
        break;
      case EnergyFinancialInstrumentType.BASE_M4:
        result = "M4";
        break;
      case EnergyFinancialInstrumentType.BASE_M5:
        result = "M5";
        break;
      case EnergyFinancialInstrumentType.BASE_M6:
        result = "M6";
        break;
      case EnergyFinancialInstrumentType.BASE_M7:
        result = "M7";
        break;
      case EnergyFinancialInstrumentType.BASE_M8:
        result = "M8";
        break;
      case EnergyFinancialInstrumentType.BASE_M9:
        result = "M9";
        break;
      case EnergyFinancialInstrumentType.BASE_M10:
        result = "M10";
        break;
      case EnergyFinancialInstrumentType.BASE_M11:
        result = "M11";
        break;
      case EnergyFinancialInstrumentType.BASE_M12:
        result = "M12";
        break;
      case EnergyFinancialInstrumentType.BASE_Q1:
        result = "Q1";
        break;
      case EnergyFinancialInstrumentType.BASE_Q2:
        result = "Q2";
        break;
      case EnergyFinancialInstrumentType.BASE_Q3:
        result = "Q3";
        break;
      case EnergyFinancialInstrumentType.BASE_Q4:
        result = "Q4";
        break;
      case EnergyFinancialInstrumentType.BASE_Y:
        result = "Y";
        break;
    }
    return result;
  }
}
