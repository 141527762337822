// import axiosConfig from "@/config/axiosConfig";

import { AxiosResponse } from "axios";
import { axiosInstance } from "@/plugins/axios";
import {
  PurchaseGroup,
  PurchaseGroupMember,
  PurchaseGroupMembers
} from "@/context/purchase_groups/model/domain";

export class PurchaseGroupApi {
  public static baseUrl: string = "/api/purchase_groups";

  public static async create(data: any): Promise<PurchaseGroupMembers> {
    const response: AxiosResponse<PurchaseGroupMembers> = await axiosInstance.post<
      PurchaseGroupMembers
    >(`${this.baseUrl}/`, data);
    response.data;
    return Promise.resolve(response.data);
  }

  public static async findOne(id: number): Promise<PurchaseGroupMembers> {
    const url = `${this.baseUrl}/${id}`;
    const response: AxiosResponse<PurchaseGroupMembers> = await axiosInstance.get<
      PurchaseGroupMembers
    >(url);
    return PurchaseGroupMembers.fromObject(response.data);
  }

  public static async findAll(query?: string): Promise<PurchaseGroup[]> {
    const url = `${this.baseUrl}/`;
    const response: AxiosResponse<PurchaseGroup[]> = await axiosInstance.get<PurchaseGroup[]>(url);
    return PurchaseGroup.fromObjectsList(response.data);
  }

  static async update(command: PurchaseGroup, id: number): Promise<PurchaseGroup> {
    const response: AxiosResponse<PurchaseGroup> = await axiosInstance.patch<PurchaseGroup>(
      `${this.baseUrl}/${id}`,
      command.toJson()
    );
    return response.data;
  }

  static async delete(id: number): Promise<AxiosResponse> {
    return await axiosInstance.delete(`${this.baseUrl}/${id}`);
  }

  static async deleteMember(memberId: number): Promise<AxiosResponse> {
    return await axiosInstance.delete(`${this.baseUrl}/members/${memberId}`);
  }

  static async addMember(id: number, member: PurchaseGroupMember): Promise<PurchaseGroupMember> {
    const response = await axiosInstance.post<PurchaseGroupMember>(
      `${this.baseUrl}/${id}/members`,
      member
    );
    return new PurchaseGroupMember(response.data.id, response.data.name);
  }

  static async autocomplete(query?: string): Promise<PurchaseGroup[]> {
    return await this.findAll(query);
  }
}
