























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

@Component({
  components: {}
})
export default class StepperPageLayout extends Vue {
  @VModel({ type: [Number, Object] }) model;
}
