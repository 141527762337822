import { axiosInstance } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import AddLotFormModel from "@/context/wallets/models/AddLotFormModel";

export class WalletApi {
  public static baseUrl: string = "/api/wallets";

  public static async createLot(lot: AddLotFormModel): Promise<AxiosResponse> {
    const url = `${this.baseUrl}/lots/create`;
    return await axiosInstance.post(url, lot);
  }

  /**
   * api/wallets/
   */
  public static async shoppingDashboard(type: string, year: number): Promise<AxiosResponse> {
    const url = `${this.baseUrl}/dashboards/shopping?year=${year}&type=${type}`;
    return await axiosInstance.get(url);
  }

  public static async volumeRecommendationData(type: string, year: number): Promise<AxiosResponse> {
    const url = `${this.baseUrl}/dashboards/recommendation?year=${year}&type=${type}`;
    return await axiosInstance.get(url);
  }

  public static async deleteVolumeRecommendation(id): Promise<AxiosResponse> {
    const url = `${this.baseUrl}/dashboards/recommendation/${id}`;
    return await axiosInstance.delete(url);
  }

  public static async purchaseXls(): Promise<AxiosResponse> {
    const url = `${this.baseUrl}/purchase_xls`;
    return await axiosInstance.get(url);
  }

  public static async lotCreationStatusData(): Promise<AxiosResponse>{
    const url = `${this.baseUrl}/lot_creation_statuses`;
    return await axiosInstance.get(url);
  }
}
