























// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";

@Component
export default class TimePeriodTypeAutocomplete extends Vue {
  @Prop({ default: "Jednostka czasu" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: "" }) hint: string;
  @Prop({ default: "" }) icon: string;
  @Prop({ default: () => [] }) rules: [];
  @VModel({ type: [Number, Object, String] }) localValue: value;
  items: { id: string; name: string }[] = [
    {
      id: "M1",
      name: "M1"
    },
    {
      id: "M2",
      name: "M2"
    },
    {
      id: "M3",
      name: "M3"
    },
    {
      id: "M4",
      name: "M4"
    },
    {
      id: "M5",
      name: "M5"
    },
    {
      id: "M6",
      name: "M6"
    },
    {
      id: "M7",
      name: "M7"
    },
    {
      id: "M8",
      name: "M8"
    },
    {
      id: "M9",
      name: "M9"
    },
    {
      id: "M10",
      name: "M10"
    },
    {
      id: "M11",
      name: "M11"
    },
    {
      id: "M12",
      name: "M12"
    },
    {
      id: "Q1",
      name: "Q1"
    },
    {
      id: "Q2",
      name: "Q2"
    },
    {
      id: "Q3",
      name: "Q3"
    },
    {
      id: "Q4",
      name: "Q4"
    },
    {
      id: "Y",
      name: "Y"
    }
  ];
}
