



















// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { IdNameModelType, value } from "@/models/CustomTypes";

@Component
export default class RecipientTypeAutocomplete extends Vue {
  @Prop({ default: "Typ odbiorcy" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: [] }) rules: [];
  @VModel({ type: [Number, Object] }) localValue: value;
  items: { id: string; name: string }[] = [
    {
      id: "NEWSLETTER",
      name: "Newsletter"
    },
    {
      id: "RECOMMENDATION",
      name: "Rekomendacje"
    },
    {
      id: "ENERGY_PRICES",
      name: "Ceny energii"
    },
    {
      id: "GAS_PRICES",
      name: "Ceny gazu"
    },
    {
      id: "RECOMMENDATION_CONFIRMATION",
      name: "Potwierdzenie reklamacji"
    }
  ];
}
