































import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class PurchaseModelInput extends Vue {
  @VModel({
    type: String
  })
  model: string;

  @Prop({
    default: null,
    type: [Number, Object]
  })
  price: any | undefined;

  @Prop({
    default: "",
    type: String
  })
  queryParam!: string;

  @Prop({ type: Array, default: () => [] })
  rules: [];

  @Prop({
    default: false
  })
  multiple: boolean;

  items: string[] = [];
  loading = false;
  search = "";

  mounted() {
    this.loadItems();
  }

  loadItems() {
    this.items = ["LOT", "CONSTANT"];
  }
}
