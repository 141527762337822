import Vue from "vue";
import { Ref } from "vue-property-decorator";
import Component from "vue-class-component";
import { VForm } from "@/models/CustomTypes";
import { ToastOptions } from "vue-toasted";

@Component({
  components: {}
})
export default class AbstractForm extends Vue {
  @Ref("form")
  protected readonly form!: VForm;
  protected submitting = false;
  protected valid = true;

  public submit() {
    this.$emit("form:submit", this.form.validate());
  }

  public resetValidation() {
    this.form.resetValidation();
  }

  public validate() {
    this.form.validate();
    return this.valid;
  }

  public resetForm() {
    this.$emit("form:reset");
    this.form.reset();
  }
  toast(options: ToastOptions, message: string): void {
    this.$toasted.show(message, options);
  }
  successToast(message: string) {
    const options: ToastOptions = {
      type: "success",
      icon: "mdi-valid",
      duration: 5 * 1000,
      position: "top-right"
    };
    this.toast(options, message);
  }
  errorToast(message: string) {
    const options: ToastOptions = {
      type: "error",
      icon: "mdi-valid",
      duration: 5 * 1000,
      position: "top-right"
    };
    this.toast(options, message);
  }
}
