import { AxiosResponse } from "axios";
import { axiosInstance } from "@/plugins/axios";

export const baseUrl = "/api/sellers";

export const SELLER_API = {
  baseUrl
};

export class SellerApi {
  public static async select(search: string, queryParam: string): Promise<AxiosResponse> {
    //SELLER_API.baserUrl + "/autocomplete/?search=" + search + "&" + queryParam
    return await axiosInstance.get(
      `${SELLER_API.baseUrl}/autocomplete/?search=${search}&${queryParam}`
    );
  }
}
