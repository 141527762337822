export enum GasFinancialInstrumentType {
  GAS_BASE_Y = "GAS_BASE_Y",
  GAS_BASE_Q1 = "GAS_BASE_Q-1",
  GAS_BASE_Q2 = "GAS_BASE_Q-2",
  GAS_BASE_Q3 = "GAS_BASE_Q-3",
  GAS_BASE_Q4 = "GAS_BASE_Q-4",
  GAS_BASE_M1 = "GAS_BASE_M-01",
  GAS_BASE_M2 = "GAS_BASE_M-02",
  GAS_BASE_M3 = "GAS_BASE_M-03",
  GAS_BASE_M4 = "GAS_BASE_M-04",
  GAS_BASE_M5 = "GAS_BASE_M-05",
  GAS_BASE_M6 = "GAS_BASE_M-06",
  GAS_BASE_M7 = "GAS_BASE_M-07",
  GAS_BASE_M8 = "GAS_BASE_M-08",
  GAS_BASE_M9 = "GAS_BASE_M-09",
  GAS_BASE_M10 = "GAS_BASE_M-10",
  GAS_BASE_M11 = "GAS_BASE_M-11",
  GAS_BASE_M12 = "GAS_BASE_M-12"
}

// export function toString();
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GasFinancialInstrumentTypeUtil {
  export function getTimePeriodType(type: GasFinancialInstrumentType): string {
    let result: string = "";
    switch (type) {
      case GasFinancialInstrumentType.GAS_BASE_M1:
        result = "M1";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M2:
        result = "M2";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M3:
        result = "M3";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M4:
        result = "M4";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M5:
        result = "M5";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M6:
        result = "M6";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M7:
        result = "M7";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M8:
        result = "M8";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M9:
        result = "M9";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M10:
        result = "M10";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M11:
        result = "M11";
        break;
      case GasFinancialInstrumentType.GAS_BASE_M12:
        result = "M12";
        break;
      case GasFinancialInstrumentType.GAS_BASE_Q1:
        result = "Q1";
        break;
      case GasFinancialInstrumentType.GAS_BASE_Q2:
        result = "Q2";
        break;
      case GasFinancialInstrumentType.GAS_BASE_Q3:
        result = "Q3";
        break;
      case GasFinancialInstrumentType.GAS_BASE_Q4:
        result = "Q4";
        break;
      case GasFinancialInstrumentType.GAS_BASE_Y:
        result = "Y";
        break;
    }
    return result;
  }
}
