import { ClientAutocompleteType, IdNameModelType } from "@/models/CustomTypes";
import { PriceApprovalFormModel } from "@/context/price-approval/models/PriceApprovalFormModel";

export class CreatePriceApprovalCmd {
  wallets: IdNameModelType[];
  periodTypes: IdNameModelType[];

  constructor(wallets: IdNameModelType[], periodTypes: IdNameModelType[]) {
    this.wallets = wallets;
    this.periodTypes = periodTypes;
  }

  static fromModel(model: PriceApprovalFormModel) {
    return new CreatePriceApprovalCmd(model.wallets, model.periodTypes);
  }
}
