





































































import Component from "vue-class-component";
import StepperPageLayout from "@/components/layouts/StepperPageLayout.vue";
import DetailForm from "@/context/agreements/components/create-agreement/forms/DetailForm.vue";
import {
  AgreementFormModel,
  FinancialInstrumentFormModel
} from "@/context/agreements/model/AgreementFormModel";
import VolumesForm from "@/context/agreements/components/create-agreement/forms/VolumesForm.vue";
import FinancialInstrumentForm from "@/context/agreements/components/create-agreement/forms/FinancialInstrumentForm.vue";
import TemplateFinancialInstrumentForm from "@/context/agreements/components/create-agreement/forms/TemplateFinancialInstrumentForm.vue";
import { VModel } from "vue-property-decorator";
import { MediaType } from "@/models/MediaType";
import {
  EnergyFinancialInstrumentType,
  EnergyFinancialInstrumentTypeUtil
} from "@/models/EnergyFinancialInstrumentType";
import {
  GasFinancialInstrumentType,
  GasFinancialInstrumentTypeUtil
} from "@/models/GasFinancialInstrumentType";
import FinancialInstrumentPreview from "@/context/agreements/components/create-agreement/FinancialInstrumentPreview.vue";
import CertificatePreview from "@/context/agreements/components/create-agreement/CertificatePreview.vue";
import Vue from "vue";
import { CertificateInstrumentType } from "@/models/CertificateInstrumentType";
import TemplateCertificateForm from "@/context/agreements/components/create-agreement/forms/TemplateCertificateForm.vue";
import { VForm } from "@/models/CustomTypes";
import { WalletType } from "@/context/shared-kernel/model/wallet-type";
import EligiblePersonsForPurchaseForm
  from "@/context/agreements/components/create-agreement/forms/EligiblePersonsForPurchaseForm.vue";

@Component({
  components: {
    TemplateCertificateForm,
    CertificatePreview,
    FinancialInstrumentPreview,
    StepperPageLayout,
    DetailForm,
    VolumesForm,
    FinancialInstrumentForm,
    TemplateFinancialInstrumentForm,
    EligiblePersonsForPurchaseForm
  }
})
export default class StepperContent extends Vue {
  @VModel()
  localModel: AgreementFormModel;

  generateFinancialInstruments(): void {
    const financialInstruments =
      this.localModel.detail.mediaType === MediaType.ENERGY
        ? Object.keys(EnergyFinancialInstrumentType)
        : Object.keys(GasFinancialInstrumentType);
    for (let i = 0; i < financialInstruments.length; i++) {
      let twoDigitYear = String(this.localModel.templateInstrument.year).substring(
        2,
        this.localModel.templateInstrument.year.length
      );

      const timePeriodType =
        this.localModel.detail.mediaType === MediaType.ENERGY
          ? EnergyFinancialInstrumentTypeUtil.getTimePeriodType(
              EnergyFinancialInstrumentType[financialInstruments[i]]
            )
          : GasFinancialInstrumentTypeUtil.getTimePeriodType(
              GasFinancialInstrumentType[financialInstruments[i]]
            );
      const fiName = financialInstruments[i] + "-" + twoDigitYear;
      let fi = new FinancialInstrumentFormModel(
        null,
        fiName,
        this.localModel.templateInstrument.orderTime,
        this.localModel.templateInstrument.priceOnDay,
        this.localModel.templateInstrument.yesterdayDifference,
        1,
        timePeriodType,
        this.localModel.templateInstrument.marginalCondition,
        this.localModel.templateInstrument.year,
        this.localModel.templateInstrument.minLotNumber,
        this.localModel.templateInstrument.maxLotNumber,
        this.localModel.templateInstrument.minVolumePerLot,
        this.localModel.templateInstrument.maxVolumePerLot,
        this.localModel.templateInstrument.meanCalculationBeginDate,
        null,
        this.localModel.templateInstrument.fee
      );
      this.localModel.financialInstruments.push(fi);
    }
  }
  getCertificateIterator(walletType: string, certificateType: CertificateInstrumentType): number {
    console.log(certificateType);
    console.log(walletType);
    switch (certificateType) {
      case CertificateInstrumentType.PMOZE_A:
        console.log(WalletType[walletType]);
        switch (WalletType[walletType]) {
          case WalletType.Q:
            return 4;
          case WalletType.M:
            return 12;
          case WalletType.Y:
          default:
            return 1;
        }
      case CertificateInstrumentType.PMOZE_BIO:
        return 1;
      case CertificateInstrumentType.PMEF_F:
      default:
        return 1;
    }
  }
  getTimePeriodType(timeIterator, walletType, certificateType): string {
    let result = "";
    switch (certificateType) {
      case CertificateInstrumentType.PMOZE_A:
        switch (walletType) {
          case "M":
            result = "M" + timeIterator;
            break;
          case "Q":
            result = "Q" + timeIterator;
            break;
          case "Y":
            result = "Y";
            break;
        }
        break;
      case CertificateInstrumentType.PMOZE_BIO:
      case CertificateInstrumentType.PMEF_F:
        result = "Y";
        break;
    }
    return result;
  }
  generateCertificates(): void {
    const certificates = Object.keys(CertificateInstrumentType);
    for (let i = 0; i < certificates.length; i++) {
      const certificateName = certificates[i];
      const certificateType = CertificateInstrumentType[certificateName];
      const walletType = WalletType.fromString(this.localModel.templateCertificate.walletType);

      let iterator = this.getCertificateIterator(walletType, certificateType);
      switch (certificateType) {
        case CertificateInstrumentType.PMOZE_A:
        case CertificateInstrumentType.PMOZE_BIO:
        case CertificateInstrumentType.PMEF_F:
          for (let j = 1; j <= iterator; j++) {
            let fi = new FinancialInstrumentFormModel(
              null,
              certificateName,
              this.localModel.templateInstrument.orderTime,
              this.localModel.templateInstrument.priceOnDay,
              this.localModel.templateInstrument.yesterdayDifference,
              1,
              this.getTimePeriodType(j, walletType, certificateType),
              this.localModel.templateInstrument.marginalCondition,
              this.localModel.templateInstrument.year,
              this.localModel.templateInstrument.minLotNumber,
              this.localModel.templateInstrument.maxLotNumber,
              this.localModel.templateInstrument.minVolumePerLot,
              this.localModel.templateInstrument.maxVolumePerLot,
              this.localModel.templateInstrument.meanCalculationBeginDate,
              null,
              this.localModel.templateInstrument.fee
            );
            this.localModel.certificates.push(fi);
          }
          break;
      }
    }
  }
  submit(): void {
    this.$emit("form:submit");
  }
  isStepValid(step: number): boolean {
    let refs = [
      "detailForm",
      "volumesForm",
      "eligibleForPurchaseForm",
      "templateFinancialInstrumentForm",
      "financialInstrumentPreview",
      "templateCertificateForm",
      "certificatePreview"
    ];
    //bo step zaczyna się od 2
    const ref = refs[step - 2];
    const formRef: VForm = this.$refs[ref] as VForm;
    return formRef.validate();
  }

  /**
   * Funkcja przygotowywująca dane do komponentów "[xxx]Preview" na podstawie komponentów "[xxx]Template".
   * @param step
   */
  emitFormStep(step: number): void {
    console.log(this.isStepValid(step));
    if (this.isStepValid(step)) {
      switch (step) {
        case 5:
          this.generateFinancialInstruments();
          (this.$refs[
            "financialInstrumentPreview"
          ] as FinancialInstrumentPreview).initializePanels();
          break;
        case 7:
          this.generateCertificates();
          (this.$refs["certificatePreview"] as CertificatePreview).initializePanels();
          break;
        case 8:
          this.submit();
          break;
      }
      this.$emit("form:step", step);
    }
  }
}
