export enum MediaType {
  ENERGY = "ENERGY",
  GAS = "GAS"
}

// export function toString();
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MediaTypeUtil {
  //TODO: Czy na pewno ta metoda powinna być tutaj? może lepiej przenieść do komponentu? Czy to ma szanse być wykorzystane gdzieś jeszcze?
}
