













































import Vue from 'vue';
import { Ref, VModel, Watch } from 'vue-property-decorator';
import AgreementAutocomplete from '@/components/form-controls/AgreementAutocomplete.vue';
import FinancialInstrumentAutocomplete from '@/components/form-controls/FinancialInstrumentAutocomplete.vue';
import Component from 'vue-class-component';
import DateInput from '@/components/form-controls/DateInput.vue';
import TimeInput from '@/components/form-controls/TimeInput.vue';
import defaultRecommendationObject from '@/context/recommendations/utils/default-recommendation-object';
import { required } from '@/context/recommendations/utils/validations';
import { AutocompleteType, ValidationFunctionType, VForm } from '@/models/CustomTypes';
import _ from 'lodash';
import { littleClientsGroup } from '@/context/recommendations/models/recommendations/LittleClientsGroup';
import TimePeriodTypeAutocomplete from '@/components/form-controls/TimePeriodTypeAutocomplete.vue';
import { PriceApprovalFormModel } from '@/context/price-approval/models/PriceApprovalFormModel';
import WalletAutocomplete from '@/components/form-controls/WalletAutocomplete.vue';
import YearInput from '@/components/form-controls/YearInput.vue';

@Component({
  components: {
    TimePeriodTypeAutocomplete,
    DateInput,
    TimeInput,
    YearInput,
    AgreementAutocomplete,
    FinancialInstrumentAutocomplete,
    WalletAutocomplete
  }
})
export default class PriceApprovalForm extends Vue {
  @Ref('walletAutocomplete')
  wa!: AutocompleteType;
  @VModel({
    default: defaultRecommendationObject(),
    type: Object
  })
  model: PriceApprovalFormModel;
  walletQueryParam: string = '';
  walletRules: [ValidationFunctionType] = [required];
  periodTypeRules: [ValidationFunctionType] = [required];

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  @Watch('model.year')
  onYearChange(newVal: number, oldVal: number) {
    if (newVal != oldVal) {
      this.walletQueryParam = `?search=${newVal}`;
    }
  }

  selectLittleClientsGroup() {
    const currentAutocompleteItems = this.wa.items;
    const littleGroupClients = littleClientsGroup();
    const toSelect = [];
    for (let i = 0; i < currentAutocompleteItems.length; i++) {
      for (let j = 0; j < littleGroupClients.length; j++) {
        if ((currentAutocompleteItems[i] as any).client_id === littleGroupClients[j].id) {
          toSelect.push(currentAutocompleteItems[i]);
        }
      }
    }
    const formModel: PriceApprovalFormModel = _.clone(this.model);
    formModel.wallets = toSelect;
    this.$emit('input', formModel);
  }

  submit(): boolean {
    return this.form.validate();
  }
}
