






















// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { IdNameModelType, value } from "@/models/CustomTypes";

@Component
export default class WalletTypeAutocomplete extends Vue {
  @Prop({ default: "Jednostka czasu" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: false }) hint: string;
  @Prop({ default: [] }) rules: [];
  @VModel({ type: [Number, Object, String] }) localValue: value;
  items: { id: string; name: string }[] = [
    {
      id: "Y",
      name: "Roczny"
    },
    {
      id: "M",
      name: "Miesięczny"
    },
    {
      id: "Q",
      name: "Kwartalny"
    }
  ];
}
