import { VolumeFormModel } from "@/context/agreements/model/AgreementFormModel";

export enum TimeUnit {
  M1 = "M1",
  M2 = "M2",
  M3 = "M3",
  M4 = "M4",
  M5 = "M5",
  M6 = "M6",
  M7 = "M7",
  M8 = "M8",
  M9 = "M9",
  M10 = "M10",
  M11 = "M11",
  M12 = "M12"
}

// export function toString();
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TimeUnitUtil {
  //TODO: Czy na pewno ta metoda powinna być tutaj? może lepiej przenieść do komponentu? Czy to ma szanse być wykorzystane gdzieś jeszcze?
  export function toVolumeMap() {
    return Object.keys(TimeUnit)
      .map((item, index, arr) => [new VolumeFormModel(0, item)])
      .reduce((result, current) => current.concat(result))
      .reverse();
  }
}
